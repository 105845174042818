import gql from 'graphql-tag'
import { createStore } from 'vuex'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'

// Setup Apollo client
// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: '/graphql',
})
// Cache implementation
const cache = new InMemoryCache()

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('auth0-token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  };
})
// Create the apollo client
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
})

export default createStore({
  state: {
    suggestedStocks: [],
    openPositions: [] as any[],
    mutedStocks: [] as any[],
    stockMetadata: new Map<string, any>(),
    availableIbkrStocks: [] as string[]
  },
  mutations: {
    setStockMetadata(state, stockMetadata) {
      state.stockMetadata = stockMetadata
    },
    setSuggestedStocks(state, suggestedStocks) {
      state.suggestedStocks = suggestedStocks
    },
    setAvailableIbkrStocks(state, availableIbkrStocks) {
      state.availableIbkrStocks = availableIbkrStocks
    },
    setOpenPositions(state, openPositions) {
      state.openPositions = openPositions
    },
    addOpenPosition(state, position) {
      state.openPositions = [...state.openPositions, position]
      state.openPositions.sort((a, b) => {
        if (a.isin < b.isin) {
          return -1
        } else if (a.isin > b.isin) {
          return 1
        } else {
          // Same ISIN
          if (a.isin < b.isin) {
            return -1
          } else if (a.open_price > b.open_price) {
            return 1
          } else {
            // Same open price
            return a.target_price - b.target_price
          }
        }
      })
    },
    deleteOpenPosition(state, positionId: string) {
      state.openPositions = state.openPositions.filter(
        x => x.id !== positionId
      )
    },
    setMutedStocks(state, mutedStocks) {
      state.mutedStocks = mutedStocks
    },
    addMutedStock(state, mutedStock) {
      state.mutedStocks = [...state.mutedStocks, mutedStock]
      state.mutedStocks.sort((a, b) => {
        if (a.isin < b.isin) {
          return -1
        } else if (a.isin > b.isin) {
          return 1
        } else {
          return 0
        }
      })
    },
    deleteMutedStock(state, isin) {
      state.mutedStocks = state.mutedStocks.filter(
        x => x.isin !== isin
      )
    }
  },
  actions: {
    initializeStockMetadata(context) {
      apolloClient.query({
        query: gql`
          query {
            stock_metadata {
              isin,
              name,
              logo,
            }
          }`,
      }
      ).then(result => {
        context.commit(
          'setStockMetadata',
          new Map<string, any>(
            result.data.stock_metadata.map((x: any) => [x.isin, { name: x.name, logo: x.logo }])
          )
        )
      }).catch((error) => {
        console.error("Unable to initialize stock metadata.")
        console.error(error)
      })
    },
    initializeSuggestedStocks(context) {
      apolloClient.query({
        query: gql`
          query {
            suggested_stocks {
              isin,
              created_at,
              value_score,
            }
          }`,
      }
      ).then(result => {
        context.commit(
          'setSuggestedStocks',
          result.data.suggested_stocks.map((x: any) => {
            return { ...x, created_at: new Date(x.created_at) }
          }).sort((a: any, b: any) => a.value_score < b.value_score ? 1 : -1)
        )
      }).catch((error) => {
        console.error("Unable to initialize suggested stocks.")
        console.error(error)
      })
    },
    initializeAvailableIbkrStocks(context) {
      apolloClient.query({
        query: gql`
          query {
            ibkr_stock_availability(where: {available: {_eq: true}}) {
              isin
            }
          }`,
      }
      ).then(result => {
        context.commit(
          'setAvailableIbkrStocks',
          result.data.ibkr_stock_availability.map((x: any) => x.isin).sort(
            (a: string, b: string) => a < b ? 1 : -1
          )
        )
      }).catch((error) => {
        console.error("Unable to initialize available IBKR stocks.")
        console.error(error)
      })
    },
    initializeOpenPositions(context) {
      apolloClient.query({
        query: gql`
          query {
            open_positions(order_by: {isin: asc, open_price: asc, target_price: asc}) {
              isin,
              id,
              open_price,
              target_price,
            }
          }`,
      }
      ).then(result => {
        context.commit('setOpenPositions', result.data.open_positions)
      }).catch((error) => {
        console.error("Unable to initialize open positions.")
        console.error(error)
      })
    },
    addOpenPosition(context, position) {
      apolloClient.mutate({
        mutation: gql`
          mutation AddOpenPosition(
            $isin: String!,
            $open_price: numeric!,
            $target_price: numeric!,
          ) {
            insert_open_positions_one(object: {
              isin: $isin,
              open_price: $open_price,
              target_price: $target_price,
            }) {
              id,
            }
          }`,
        variables: {
          isin: position.isin,
          open_price: position.openPrice,
          target_price: position.targetPrice
        }
      }).then(result => {
        position = {
          id: result.data.insert_open_positions_one.id,
          isin: position.isin,
          open_price: position.openPrice,
          target_price: position.targetPrice
        }
        context.commit('addOpenPosition', position)
      }).catch((error) => {
        console.error("Unable to add open position.")
        console.error(error)
      })
    },
    deleteOpenPosition(context, positionId) {
      apolloClient.mutate({
        mutation: gql`
          mutation DeleteOpenPosition($id: uuid!) {
            delete_open_positions_by_pk(id: $id) {
              id,
            }
          }`,
        variables: {
          id: positionId
        }
      }).then(() => {
        context.commit('deleteOpenPosition', positionId)
      }).catch((error) => {
        console.error("Unable to delete open position.")
        console.error(error)
      })
    },
    initializeMutedStocks(context) {
      apolloClient.query({
        query: gql`
          query {
            muted_stocks(order_by: {isin: asc}) {
              isin,
              expiration_date,
              target_price,
            }
          }`,
      }).then(result => {
        context.commit('setMutedStocks', result.data.muted_stocks)
      }).catch((error) => {
        console.error("Unable to initialize muted stocks.")
        console.error(error)
      })
    },
    addMutedStock(context, mutedStock) {
      const expirationDate = new Date()
      expirationDate.setDate(expirationDate.getDate() + mutedStock.muteDays)
      apolloClient.mutate({
        mutation: gql`
          mutation AddMutedStock(
            $isin: String!,
            $expiration_date: date!,
            $target_price: numeric!,
          ) {
            insert_muted_stocks_one(object: {
              isin: $isin,
              expiration_date: $expiration_date,
              target_price: $target_price,
            }) {
              isin,
              expiration_date,
              target_price,
            }
          }`,
        variables: {
          isin: mutedStock.isin,
          expiration_date: expirationDate,
          target_price: mutedStock.targetPrice
        }
      }).then(result => {
        context.commit('addMutedStock', result.data.insert_muted_stocks_one)
      }).catch((error) => {
        console.error("Unable to add muted stock.")
        console.error(error)
      })
    },
    deleteMutedStock(context, isin) {
      apolloClient.mutate({
        mutation: gql`
          mutation DeleteMutedStock($isin: String!) {
            delete_muted_stocks_by_pk(isin: $isin) {
              isin,
            }
          }`,
        variables: {
          isin: isin
        }
      }).then(() => {
        context.commit('deleteMutedStock', isin)
      }).catch((error) => {
        console.error("Unable to delete muted stock.")
        console.error(error)
      })
    }
  },
  modules: {
  }
})
