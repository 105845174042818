import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createVNode(_component_router_link, { to: "/suggested-stocks" }, {
          default: _withCtx(() => [
            _createTextVNode("Suggestions")
          ]),
          _: 1
        }),
        _createTextVNode(" | "),
        _createVNode(_component_router_link, { to: "/open-positions" }, {
          default: _withCtx(() => [
            _createTextVNode("Positions")
          ]),
          _: 1
        }),
        _createTextVNode(" | "),
        _createVNode(_component_router_link, { to: { name: 'muted-stocks' } }, {
          default: _withCtx(() => [
            _createTextVNode("Muted")
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_router_view)
  ], 64))
}