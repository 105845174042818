import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/suggested-stocks'
  },
  {
    path: '/suggested-stocks',
    name: 'suggested-stocks',
    component: () => import('../views/SuggestedStocks.vue')
  },
  {
    path: '/stock/:isin',
    name: 'stock',
    component: () => import('../views/StockDetail.vue')
  },
  {
    path: '/open-positions',
    name: 'open-positions',
    component: () => import('../views/OpenPositions.vue')
  },
  {
    path: '/muted-stocks',
    name: 'muted-stocks',
    component: () => import('../views/MutedStocks.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
