
import { watch } from 'vue';
import { useStore } from 'vuex';
import { Vue } from 'vue-class-component';

export default class App extends Vue {

  created(): void {

    const store = useStore()

    const initializeData = () => {
      this.$auth0.getAccessTokenSilently({
        detailedResponse: true
      }).then(response => {
        localStorage.setItem('auth0-token', (response as any).id_token)
        store.dispatch('initializeStockMetadata')
        store.dispatch('initializeSuggestedStocks')
        store.dispatch('initializeOpenPositions')
        store.dispatch('initializeMutedStocks')
        store.dispatch('initializeAvailableIbkrStocks')
      })
    }

    watch(
      [this.$auth0.isLoading, this.$auth0.isAuthenticated],
      ([newIsLoading, newIsAuthenticated]) => {
        if (newIsLoading === true) return;
        if (newIsAuthenticated === true) {
          initializeData()
        } else if (newIsAuthenticated === false) {
          this.$auth0.loginWithRedirect({
            appState: {
              target: window.location.pathname + window.location.search
            }
          })
        }
      }
    )
  }
}
