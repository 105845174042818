import { createApp, h } from 'vue'
import { createAuth0 } from '@auth0/auth0-vue'

import App from './App.vue'
import router from './router'
import store from './store'


// Setup Vue app
const app = createApp({
  render: () => h(App)
})
app.use(store)
app.use(router)
app.use(
  createAuth0({
    domain: process.env.VUE_APP_MERCATURA_HASURA_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_MERCATURA_HASURA_AUTH0_CLIENT_ID,
    authorizationParams: {
      redirect_uri: process.env.VUE_APP_MERCATURA_HASURA_AUTH0_REDIRECT_URI,
    }
  })
)
app.mount('#app')